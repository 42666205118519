var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h2',[_vm._v("จัดการแท็ก")]),_c('v-card',{staticClass:"mt-4 px-6 pt-6"},[_c('v-text-field',{attrs:{"outlined":"","placeholder":"ค้นหา ...","dense":""},model:{value:(_vm.datatable.search),callback:function ($$v) {_vm.$set(_vm.datatable, "search", $$v)},expression:"datatable.search"}})],1),_c('v-card',{staticClass:"mt-4"},[_c('div',{staticClass:"d-flex pa-4"},[_c('v-btn',{attrs:{"disabled":_vm.datatable.selectedItems.length == 0},on:{"click":function($event){return _vm.deleteMultiple()}}},[_c('v-icon',[_vm._v(_vm._s(_vm.mdiDeleteOutline))])],1),_c('v-btn',{staticClass:"ml-auto",attrs:{"color":"primary"},on:{"click":function($event){return _vm.$router.push({ 'name': 'customertag_create', params: { customertag_id: 'create' } })}}},[_vm._v("เพิ่มแท็ก")])],1),_c('delay-datatable',{ref:"mainDatatable",attrs:{"show-select":"","headers":_vm.datatable.headers,"url":'/customer/tag/list',"queryParams":_vm.getDatatableQueryParams,"options":_vm.datatable.options,"isSaveState":true},on:{"update:options":function($event){return _vm.$set(_vm.datatable, "options", $event)},"loadState":_vm.loadDatatableState},scopedSlots:_vm._u([{key:"header.data-table-select",fn:function(ref){
var props = ref.props;
var on = ref.on;
return [_c('v-simple-checkbox',_vm._g({attrs:{"value":props.value || props.indeterminate,"indeterminate":props.indeterminate,"color":"primary","ripple":false}},on))]}},{key:"item.data-table-select",fn:function(ref){
var isSelected = ref.isSelected;
var select = ref.select;
return [_c('v-simple-checkbox',{attrs:{"color":"primary","ripple":false},on:{"input":select},model:{value:(isSelected),callback:function ($$v) {isSelected=$$v},expression:"isSelected"}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$router.push({name: 'customertag_create', params: { customertag_id: item.id }})}}},[_c('v-icon',[_vm._v(_vm._s(_vm.$store.getters['auth/GET_ABILITY'].can('update', 'Product') ? _vm.mdiPlaylistEdit : _vm.mdiEyeOutline))])],1),_c('v-btn',{attrs:{"icon":"","disabled":!_vm.$store.getters['auth/GET_ABILITY'].can('delete', 'Product') || item.customers_count > 0},on:{"click":function($event){return _vm.deleteSingle(item.id, item.name)}}},[_c('v-icon',[_vm._v(_vm._s(_vm.mdiDeleteOutline))])],1)]}}],null,true),model:{value:(_vm.datatable.selectedItems),callback:function ($$v) {_vm.$set(_vm.datatable, "selectedItems", $$v)},expression:"datatable.selectedItems"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }